import { useState } from 'react';
import { useApi } from '@/hooks/use-api';
import api from '@/utilities/api';

import DomainsDetailView from '@/components/DomainsDetail/DomainsDetailView';

const defaultProps = {};

type DomainsDetailContainerProps = {
  organizationId: string | number;
  domainId: string | number;
} & typeof defaultProps;

export function DomainsDetailContainer({
  organizationId,
  domainId,
}: DomainsDetailContainerProps) {
  const options = {};
  const params = [organizationId, domainId];
  const { data, error, loading } = useApi(
    api.domains.get.bind(api.domains),
    params,
    options,
  );
  const [isManageMenuOpen, setIsManageMenuOpen] = useState(false);

  return (
    <DomainsDetailView
      domain={data?.domain}
      domainId={domainId}
      error={error}
      isManageMenuOpen={isManageMenuOpen}
      loading={loading}
      setIsManageMenuOpen={setIsManageMenuOpen}
      organizationId={organizationId}
    />
  );
}

DomainsDetailContainer.defaultProps = defaultProps;

export default DomainsDetailContainer;
