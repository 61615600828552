import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ButtonDropdownMenu from '@/components/core/ButtonDropdownMenu';
import DomainsDetailActions from '@/components/DomainsDetailActions';
import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingDomain20211012Domain } from '@clients/landing-domain';

const defaultProps = {};

type DomainsDetailViewProps = {
  domain: LandingDomain20211012Domain;
  domainId: string | number;
  error: any;
  isManageMenuOpen: boolean;
  loading: boolean;
  setIsManageMenuOpen: Function;
  organizationId: string | number;
} & typeof defaultProps;

export function DomainsDetailView({
  domain,
  domainId,
  error,
  isManageMenuOpen,
  loading,
  setIsManageMenuOpen,
  organizationId,
}: DomainsDetailViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}/domains`}
            underline="hover"
          >
            {t(`domains.domains`)}
          </Link>
          <Typography color="text.primary" sx={{ minWidth: `50px` }}>
            {loading ? <Skeleton /> : domain?.name}
          </Typography>
        </Breadcrumbs>
        {!error && (
          <Stack
            direction={{ xs: `row`, sm: `row` }}
            justifyContent="space-between"
          >
            <Box>
              <Typography
                color="inherit"
                component="div"
                variant="h1"
                sx={{ minWidth: `400px`, mb: { xs: 1, sm: 0 } }}
              >
                {loading ? <Skeleton /> : domain?.name}
              </Typography>
              <Stack
                direction="row"
                spacing={1.5}
                sx={{ my: 1.5 }}
                alignItems="center"
              >
                {loading ? (
                  <>
                    <Skeleton width="50px" />
                    <Skeleton width="200px" />
                  </>
                ) : (
                  <>
                    <Chip color="success" label={domain?.state} size="small" />
                    <Typography color="text.secondary">
                      {t(`domains.created-at`)}
                      &nbsp;
                      {new Intl.DateTimeFormat(`en-US`, {
                        dateStyle: `medium`,
                        timeStyle: `short`,
                      }).format(domain?.createdAt)}
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
            <Box>
              <ButtonDropdownMenu
                aria-label={t(`domains.manage`)}
                disabled={loading}
                isOpen={isManageMenuOpen}
                label={t(`domains.manage`)}
                onClick={() => setIsManageMenuOpen(true)}
                onClose={() => setIsManageMenuOpen(false)}
              >
                <DomainsDetailActions
                  domainId={domain?.id}
                  isOpen={isManageMenuOpen}
                  onClose={() => setIsManageMenuOpen(false)}
                  organizationId={organizationId}
                />
              </ButtonDropdownMenu>
            </Box>
          </Stack>
        )}
      </Box>
      <Box>
        {loading ? (
          <PageLoading></PageLoading>
        ) : error ? (
          <PageError>{error.message}</PageError>
        ) : domain ? (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Paper elevation={0} component={Card}>
                <CardHeader title="Details" sx={{ px: 0, py: 1.5 }} />
                <CardContent sx={{ p: 0 }}>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        sx={{ maxWidth: 1 / 6 }}
                        primaryTypographyProps={{ sx: { color: `grey.700` } }}
                        primary={`ID`}
                      />
                      <ListItemText primary={domainId} />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions></CardActions>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

DomainsDetailView.defaultProps = defaultProps;

export default DomainsDetailView;
