import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import DomainsDetail from '@/components/DomainsDetail';

export function DomainsByIdIndex({
  organizationId,
  domainId,
}: {
  organizationId: string;
  domainId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      {<DomainsDetail organizationId={organizationId} domainId={domainId} />}
    </AppFrame>
  );
}

export default withAuthenticationRequired(DomainsByIdIndex);
